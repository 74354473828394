window.onload = function () {
	let lastScrollY = 0;
	let animatingHeader = false;
	// The debounce function receives our function as a parameter
	const debounce = (fn) => {

	  // This holds the requestAnimationFrame reference, so we can cancel it if we wish
	  let frame;

	  // The debounce function returns a new function that can receive a variable number of arguments
	  return (...params) => {
	    
	    // If the frame variable has been defined, clear it now, and queue for next frame
	    if (frame) { 
	      cancelAnimationFrame(frame);
	    }

	    // Queue our function call for the next frame
	    frame = requestAnimationFrame(() => {
	      
	      // Call our function and pass any params we received
	      fn(...params);
	    });

	  } 
	};


	// Reads out the scroll position and stores it in the data attribute
	// so we can use it in our stylesheets
	const storeScroll = () => {
		let windowScroll = window.scrollY;
		let add = [];
		let remove = [];
		let scrollUp = (lastScrollY > windowScroll);

	  if ( windowScroll > 200 ) {
	  	document.body.classList.add("scrolled");
	  } else {
	  	document.body.classList.remove("scrolled");
	  }
	  if ($( window ).width() <= 991) {
	  	let header = $(".header");
	  	if (scrollUp) {
	  		if (!animatingHeader) {
	  			// add shadow class if necessary on mobile
					header.toggleClass("mobile-shadow", (windowScroll > 0));
	  			header.stop(true, false);
		  		animatingHeader = true;
		  		header.animate({top: 0}, 200, function() {
		  			animatingHeader = false;
		  		});
	  		}
	  	} else {
	  		header.stop(true, false);
  			animatingHeader = false;
				let scrollAmount = windowScroll - lastScrollY;
		  	let lastTop = parseInt(header.css("top"), 10);
		  	let headerHeight = header.outerHeight();
		  	let newTop = Math.min(Math.max(lastTop - scrollAmount, -headerHeight - 5), 0);
		  	header.css("top", newTop + "px");
	  		// add shadow class if necessary on mobile
	  		header.toggleClass("mobile-shadow", (windowScroll + newTop > 0));
	  	}
	  }
	  lastScrollY = windowScroll;
	}

	// Listen for new scroll events, here we debounce our `storeScroll` function
	document.addEventListener('scroll', debounce(storeScroll), { passive: true });

	// Update scroll position for first time
	storeScroll();

}